<script setup lang="ts">
import type {
  PowerActionEventLog,
  PowerActionEventLogMessage,
  ExternalPowerActionObservation,
} from '@console/services/aws/arm/api.models';
import type { Moment } from 'moment';
import type { PropType } from 'vue';

import moment from 'moment';
import { computed, ref, watch } from 'vue';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

interface SeriesDefinition {
  name: string;
  field: string;
}

interface SeriesData {
  name: string;
  field: string;
  label: string;
  type: string;
  stacking: string;
  color: string;
  data: number[];
  legendSelected: boolean;
  legendOrder: number;
}

interface GroupedSeriesData {
  [date: string]: { [actionKey: string]: number };
}

interface XAxis {
  categories: string[];
}

interface YAxisValue {
  min: number;
  max: number;
  title: {
    text: null;
  };
}

interface YAxis extends Array<YAxisValue> {}

const props = defineProps({
  powerActionData: {
    type: Array as PropType<PowerActionEventLog[]>,
    required: true,
  },
  sinceDays: {
    type: Number,
    required: true,
  },
});

const seriesColors: { [key: string]: string } = {
  power_on_failed: '#b9f6ca',
  power_on_skipped: '#69f0ae',
  power_on_success: '#00c853',
  power_off_failed: '#ffcdd2',
  power_off_skipped: '#e57373',
  power_off_success: '#f44336',
  power_on_external: '#e0e0e0',
  power_off_external: '#e0e0e0',
};

function getSeriesColor(field: string): string {
  return seriesColors[field];
}

const actionKeyMap: { [key: string]: { [key: string]: string } } = {
  PowerActionOn: {
    Failed: 'power_on_failed',
    Skipped: 'power_on_skipped',
    Succeeded: 'power_on_success',
    External: 'power_on_external',
  },
  PowerActionOff: {
    Failed: 'power_off_failed',
    Skipped: 'power_off_skipped',
    Succeeded: 'power_off_success',
    External: 'power_off_external',
  },
};

function getActionKey(message: PowerActionEventLogMessage | ExternalPowerActionObservation) {
  // Scheduled power action event
  if (message.event_type === 'PowerAction') {
    return actionKeyMap[message.activity_type]?.[message.action_outcome] || null;
  } else {
    // External power action event
    return actionKeyMap[message.activity_type]?.External || null;
  }
}

// Given startDate and sinceDays create the x axis buckets. Each date is a possible label and must correspond 1:1 with
// the length of the series data. It must be formatted correctly as per 15min, hourly, or daily.
function getXAxisDates(startDate: Moment, sinceDays: number) {
  const dates = new Set();

  switch (sinceDays) {
    case 1: {
      const flooredDailyDate = getDate(startDate.clone(), sinceDays);
      const currentDateTime = flooredDailyDate.clone();
      const endTime = flooredDailyDate.clone().add(1, 'days');

      while (currentDateTime.isBefore(endTime)) {
        dates.add(currentDateTime.format('MMM D HH:mm'));
        currentDateTime.add(15, 'minutes');
      }
      break;
    }
    case 7:
    case 14: {
      const flooredHourlyDate = getDate(startDate.clone(), sinceDays);
      const currentDateTime = flooredHourlyDate.clone();
      const endTime = flooredHourlyDate.clone().add(sinceDays, 'days');

      while (currentDateTime.isSameOrBefore(endTime)) {
        dates.add(currentDateTime.format('MMM D HH:mm'));
        currentDateTime.add(1, 'hours');
      }
      break;
    }
    case 30:
    case 90: {
      for (let i = 0; i <= sinceDays; i++) {
        dates.add(moment(startDate).add(i, 'days').format('MMM D'));
      }
      break;
    }
    default:
      throw new Error('Unsupported sinceDays lookback period');
  }
  return Array.from(dates, date => String(date));
}

function getDateString(date: Moment, sinceDays: number) {
  const localDate = moment(date).local();

  switch (sinceDays) {
    case 1: {
      const minutes = localDate.minutes();
      const flooredMinutes = Math.floor(minutes / 15) * 15;
      localDate.minutes(flooredMinutes).seconds(0);
      return localDate.format('MMM D HH:mm');
    }
    case 7:
    case 14: {
      localDate.minutes(0);
      return localDate.format('MMM D HH:mm');
    }
    case 30:
    case 90:
      return localDate.format('MMM D');
    default:
      throw new Error('Unsupported sinceDays lookback period');
  }
}

function getDate(date: Moment, sinceDays: number) {
  switch (sinceDays) {
    case 1: {
      const minutes = date.minutes();
      const flooredMinutes = Math.floor(minutes / 15) * 15;
      return date.minutes(flooredMinutes).seconds(0);
    }
    case 7:
    case 14:
      return date.minutes(0).seconds(0);
    case 30:
    case 90:
      return date.hours(0).minutes(0).seconds(0);
    default:
      throw new Error('Unsupported sinceDays lookback period');
  }
}

const xAxis = computed<XAxis>(() => {
  const startDate = moment().local().subtract(props.sinceDays, 'days');
  const categories = getXAxisDates(startDate, props.sinceDays);
  return { categories };
});

const yAxis = computed<YAxis>(() => {
  const groupedData = groupDataByDateAndOutcome(props.powerActionData, props.sinceDays);
  let maxValue = 0;

  Object.keys(groupedData).forEach(date => {
    const totalForDate = Object.values(groupedData[date]).reduce((sum, value) => sum + value, 0);
    if (totalForDate > maxValue) {
      maxValue = totalForDate;
    }
  });

  return [
    {
      min: 0,
      max: maxValue,
      title: {
        text: null,
      },
    },
  ];
});

function groupDataByDateAndOutcome(data: PowerActionEventLog[], sinceDays: number) {
  const startDate = moment().local().subtract(sinceDays, 'days');
  const groupedDataForDate: { [key: string]: { [key: string]: number } } = {};
  const defaultActionData: { [key: string]: number } = {
    power_off_success: 0,
    power_off_failed: 0,
    power_off_skipped: 0,
    power_on_success: 0,
    power_on_failed: 0,
    power_on_skipped: 0,
    power_on_external: 0,
    power_off_external: 0,
  };
  const xAxisDates = getXAxisDates(startDate, sinceDays);

  xAxisDates.forEach(date => {
    groupedDataForDate[date] = { ...defaultActionData };
  });

  data.forEach((item: PowerActionEventLog) => {
    const actionDate = getDateString(moment(item.created_date).local(), sinceDays);
    const key = getActionKey(item.message);

    if (key) {
      groupedDataForDate[actionDate][key] += 1;
    }
  });
  return groupedDataForDate;
}

function createSeries(groupedData: GroupedSeriesData) {
  const seriesDefs: SeriesDefinition[] = [
    { name: 'Start (Failed)', field: 'power_on_failed' },
    { name: 'Start (Skipped)', field: 'power_on_skipped' },
    { name: 'Start (Success)', field: 'power_on_success' },
    { name: 'Stop (Failed)', field: 'power_off_failed' },
    { name: 'Stop (Skipped)', field: 'power_off_skipped' },
    { name: 'Stop (Success)', field: 'power_off_success' },
    { name: 'Start (External)', field: 'power_on_external' },
    { name: 'Stop (External)', field: 'power_off_external' },
  ];

  const seriesData: SeriesData[] = seriesDefs.map((def, index) => ({
    ...def,
    label: def.name,
    type: 'column',
    stacking: 'normal',
    color: getSeriesColor(def.field),
    data: Object.keys(groupedData).map(date => groupedData[date][def.field] || 0),
    legendSelected: true,
    legendOrder: index,
  }));

  return seriesData;
}

// Series data is updated by the legend so it must use a ref + watcher
const series = ref<SeriesData[]>([]);

watch(
  () => [props.powerActionData, props.sinceDays],
  () => {
    series.value = createSeries(groupDataByDateAndOutcome(props.powerActionData, props.sinceDays));
  },
  { immediate: true }
);

// Filtered series is read only and can be computed based on series data
const filteredSeries = computed<SeriesData[]>(() => series.value.filter(s => s.legendSelected));
</script>

<template>
  <div>
    <ChartLegend v-model="series" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" :height="200" />
  </div>
</template>

<style scoped lang="scss">
.legend {
  height: 20px;
}
</style>
